@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@layer components {
    
  .mobile{
    @apply flex flex-col absolute top-0 left-0 w-full bg-white px-5 py-[0.75rem] transition ease-in-out h-[100vh];
  }
 
/* 
  .desktop{
    @apply  bg-orange-300 block
  } */
}

